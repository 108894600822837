export function useGetHashFromUrl() {
  const [hash, setHash] = React.useState(null)

  React.useEffect(() => {
    window.addEventListener('scroll', updateActiveHash)
    return () => window.removeEventListener('scroll', updateActiveHash)

    function updateActiveHash() {
      setHash(window.location.hash.slice(1))
    }
  }, [])

  return hash
}
