export function useScrollActivity({ delta }) {
  const [direction, setDirection] = React.useState(null)
  const [hasScrolled, setHasScrolled] = React.useState(false)

  React.useEffect(
    () => {
      let lastScrollY = window.scrollY
      let tick = false

      onScroll()

      window.addEventListener('scroll', onScroll)
      window.addEventListener('resize', onScroll)

      return () => {
        window.removeEventListener('scroll', onScroll)
        window.removeEventListener('resize', onScroll)
      }

      function handleScroll() {
        const scrollY = window.scrollY

        if (Math.abs(scrollY - lastScrollY) < delta) {
          tick = false
          return
        }

        setHasScrolled(true)
        setDirection(scrollY > lastScrollY ? 'down' : 'up')

        lastScrollY = scrollY > 0 ? scrollY : 0
        tick = false
      }

      function onScroll() {
        if (!tick) {
          window.requestAnimationFrame(handleScroll)
          tick = true
        }
      }
    },
    [delta]
  )

  return { direction, hasScrolled }
}
