export function useScrollDepth({ offset = 0 } = {}) {
  const [scrolledBeyondViewport, setScrolledBeyondViewport] = React.useState(false)

  React.useEffect(
    () => {
      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }

      function handleScroll() {
        setScrolledBeyondViewport(window.scrollY > window.innerHeight - offset)
      }
    },
    [offset]
  )

  return scrolledBeyondViewport
}
